type Props = {
  active: boolean;
};

export const Icon2 = (props: Props) => {
  if (props.active) {
    return null;
  }

  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 1.5C7.39543 1.5 6.5 2.39543 6.5 3.5C6.5 4.60457 7.39543 5.5 8.5 5.5C9.60457 5.5 10.5 4.60457 10.5 3.5C10.5 2.39543 9.60457 1.5 8.5 1.5ZM5.5 3.5C5.5 1.84315 6.84315 0.5 8.5 0.5C9.6551 0.5 10.6577 1.15283 11.1591 2.10965H19.8453C20.7385 2.10965 21.5638 2.58615 22.0104 3.35965L26.8557 11.752C27.3022 12.5255 27.3022 13.4785 26.8557 14.252L22.0104 22.6443C21.5638 23.4178 20.7385 23.8943 19.8453 23.8943L10.1547 23.8943C9.26153 23.8943 8.43622 23.4178 7.98964 22.6443L4.44201 16.4996C2.25961 16.4686 0.5 14.6898 0.5 12.5C0.5 10.2909 2.29086 8.5 4.5 8.5C4.67061 8.5 4.83873 8.51068 5.00372 8.53141L6.57844 5.80392C5.91934 5.25361 5.5 4.42578 5.5 3.5ZM6.00704 8.79361C7.46922 9.38875 8.5 10.824 8.5 12.5C8.5 14.3563 7.23558 15.9172 5.52103 16.3685L8.85566 22.1443C9.12361 22.6084 9.6188 22.8943 10.1547 22.8943L19.8453 22.8943C20.3812 22.8943 20.8764 22.6084 21.1443 22.1443L25.9896 13.752C26.2576 13.2879 26.2576 12.7161 25.9896 12.252L21.1443 3.85965C20.8764 3.39555 20.3812 3.10965 19.8453 3.10965L11.4748 3.10965C11.4914 3.23742 11.5 3.36771 11.5 3.5C11.5 5.15685 10.1569 6.5 8.5 6.5C8.1276 6.5 7.77105 6.43215 7.44203 6.30813L6.00704 8.79361ZM1.5 12.5C1.5 10.8431 2.84315 9.5 4.5 9.5C6.15685 9.5 7.5 10.8431 7.5 12.5C7.5 14.1569 6.15685 15.5 4.5 15.5C2.84315 15.5 1.5 14.1569 1.5 12.5Z"
        fill="white"
      />
      <line
        x1="12.5"
        y1="9"
        x2="17.5"
        y2="9"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="10.5"
        y1="13.5"
        x2="19.5"
        y2="13.5"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};
