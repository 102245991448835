export const Logo = () => {
  return (
    <svg
      width="28"
      height="30"
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8855 3.35198C8.37927 3.35198 3.10495 8.56695 3.10495 14.9999C3.10495 21.4329 8.37927 26.6479 14.8855 26.6479C18.8904 26.6479 22.4301 24.6727 24.5603 21.6479C24.6501 21.5203 24.6431 21.35 24.5464 21.2275L22.9574 19.214C22.9065 19.1494 22.9058 19.0591 22.9558 18.9938L24.6946 16.7242C24.7675 16.629 24.9121 16.6288 24.9853 16.7236L27.5174 20.0017C28.0704 20.7025 28.1776 21.6994 27.6847 22.5182C25.0914 26.826 20.3308 29.7179 14.8855 29.7179C6.66446 29.7179 0 23.1284 0 14.9999C0 6.87143 6.66446 0.281982 14.8855 0.281982C20.3308 0.281982 25.0914 3.17385 27.6847 7.48169C28.1776 8.30042 28.0704 9.29733 27.5174 9.99813L20.1744 19.3031L20.1596 19.3207C18.9058 20.8156 17.0079 21.772 14.8855 21.772C11.1028 21.772 8.03634 18.74 8.03634 14.9999C8.03634 11.2598 11.1028 8.22787 14.8855 8.22787C17.0079 8.22787 18.9058 9.18423 20.1596 10.6792L20.1744 10.6968L21.6011 12.5047L24.5464 8.77233C24.6431 8.64982 24.6501 8.47952 24.5603 8.35199C22.4301 5.32718 18.8904 3.35198 14.8855 3.35198ZM19.6319 14.9999L17.756 12.6229C17.0668 11.8103 16.0374 11.2979 14.8855 11.2979C12.8176 11.2979 11.1413 12.9553 11.1413 14.9999C11.1413 17.0445 12.8176 18.702 14.8855 18.702C16.0374 18.702 17.0668 18.1895 17.7561 17.377L19.6319 14.9999Z"
        fill="white"
      />
    </svg>
  );
};
