import { PropsWithChildren } from "react";
import { Outlet } from "react-router";
import styled from "styled-components";

import { Colors } from "../../styles/themeNew";
import { Logo } from "./components/Logo";
import { Icon1 } from "./components/Icon1";
import { Icon2 } from "./components/Icon2";
import { Icon3 } from "./components/Icon3";
import { Icon4 } from "./components/Icon4";
import { Icon5 } from "./components/Icon5";

type Props = {};

export const MainLayout = (props: PropsWithChildren<Props>) => {
  return (
    <StyledContainer>
      <StyledMenuContainer>
        <StyledLogoContainer>
          <Logo />
        </StyledLogoContainer>

        <StyledTopIconsContainer>
          <StyledIconContainer active={true}>
            <Icon1 active={true} />
          </StyledIconContainer>

          <StyledIconContainer>
            <Icon2 active={false} />
          </StyledIconContainer>

          <StyledIconContainer>
            <Icon3 active={false} />
          </StyledIconContainer>
        </StyledTopIconsContainer>

        <StyledBottomIconsContainer>
          <StyledIconContainer>
            <Icon4 active={false} />
          </StyledIconContainer>

          <StyledIconContainer>
            <Icon5 active={false} />
          </StyledIconContainer>
        </StyledBottomIconsContainer>
      </StyledMenuContainer>

      <StyledMainContentContainer>
        <Outlet />
      </StyledMainContentContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const STATIC_MENU_WIDTH = "72px";

const StyledMenuContainer = styled.div`
  width: ${STATIC_MENU_WIDTH};
  min-height: 100vh;

  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* position: static; */
  position: fixed;
  left: 0;
  top: 0;

  background-color: ${Colors.BRAND[500]};

  padding: 20px 8px 8px 8px;
  flex-direction: column;

  border-radius: 0px 6px 6px 0px;
  background: #3c67f2;
`;

const StyledMainContentContainer = styled.div`
  width: calc(100% - ${STATIC_MENU_WIDTH});
  height: auto;
  padding-left: ${STATIC_MENU_WIDTH};
`;

const StyledLogoContainer = styled.div`
  width: 52px;
  height: 52px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 26px;
`;

const StyledIconContainer = styled.div<{ active?: boolean }>`
  width: 52px;
  height: 52px;
  border-radius: 6px 6px 2px 6px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => (props.active ? "white" : "transparent")};
`;

const StyledTopIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 13px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;
const StyledBottomIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 13px;
`;
