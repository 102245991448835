type Props = {
  active: boolean;
};

export const Icon1 = (props: Props) => {
  if (props.active) {
    return (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="15"
          width="10"
          height="10"
          rx="2"
          stroke="#3E6AFF"
          strokeLinejoin="round"
        />
        <rect
          x="15"
          y="1"
          width="10"
          height="10"
          rx="2"
          stroke="#3E6AFF"
          strokeLinejoin="round"
        />
        <rect
          x="15"
          y="15"
          width="10"
          height="10"
          rx="2"
          stroke="#3E6AFF"
          strokeLinejoin="round"
        />
        <rect
          x="1"
          y="1"
          width="10"
          height="10"
          rx="2"
          stroke="#3E6AFF"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return null;
};
