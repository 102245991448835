type Props = {
  active: boolean;
};

export const Icon3 = (props: Props) => {
  if (props.active) {
    return null;
  }

  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 13.5C25 15.7745 24.2962 17.9979 22.9776 19.8891C21.6591 21.7802 19.7849 23.2542 17.5922 24.1246C15.3995 24.995 12.9867 25.2228 10.6589 24.779C8.33114 24.3353 6.19295 23.24 4.51472 21.6317C2.83649 20.0234 1.6936 17.9743 1.23058 15.7435C0.767554 13.5128 1.00519 11.2005 1.91345 9.09914C2.8217 6.99779 4.35977 5.20174 6.33316 3.9381C8.30655 2.67446 10.6266 2 13 2L13 13.5H25Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <path
        d="M26 11C26 9.68678 25.7413 8.38642 25.2388 7.17317C24.7362 5.95991 23.9997 4.85752 23.0711 3.92893C22.1425 3.00035 21.0401 2.26375 19.8268 1.7612C18.6136 1.25866 17.3132 1 16 1L16 11H26Z"
        stroke="white"
        strokeLinejoin="round"
      />
    </svg>
  );
};
