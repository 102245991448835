type Props = {
  active: boolean;
};

export const Icon5 = (props: Props) => {
  if (props.active) {
    return null;
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12V20C3 21.6569 4.34315 23 6 23H20C21.6569 23 23 21.6569 23 20V6C23 4.34315 21.6569 3 20 3H12"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L10.6464 11.3536ZM1 0.5C0.723858 0.5 0.5 0.723858 0.5 1L0.5 5.5C0.5 5.77614 0.723858 6 1 6C1.27614 6 1.5 5.77614 1.5 5.5L1.5 1.5L5.5 1.5C5.77614 1.5 6 1.27614 6 1C6 0.723858 5.77614 0.5 5.5 0.5L1 0.5ZM11.3536 10.6464L1.35355 0.646447L0.646447 1.35355L10.6464 11.3536L11.3536 10.6464Z"
        fill="white"
      />
    </svg>
  );
};
