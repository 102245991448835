type Props = {
  active: boolean;
};

export const Icon4 = (props: Props) => {
  if (props.active) {
    return null;
  }

  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.183 17H1.81797C1.33602 16.9993 0.873994 16.8087 0.533201 16.4699C0.192409 16.1311 0.000661206 15.6718 0 15.1926V1.68763C0.000529391 1.24012 0.179612 0.811091 0.497949 0.4947C0.816286 0.178308 1.24787 0.000394553 1.698 0H22.3025C22.7525 0.000526073 23.184 0.178498 23.5022 0.494875C23.8205 0.811252 23.9995 1.2402 24 1.68763V15.1931C23.9995 15.6721 23.8079 16.1313 23.4673 16.47C23.1266 16.8087 22.6648 16.9993 22.183 17ZM1.6985 0.993394C1.31312 0.993394 1.00021 1.30448 1.00021 1.68713V15.1926C1.00021 15.6409 1.3671 16.0061 1.81847 16.0061H22.1835C22.6344 16.0061 23.0013 15.6414 23.0013 15.1926V1.68763C23.0013 1.30448 22.6884 0.993891 22.3035 0.993891L1.6985 0.993394Z"
        fill="white"
      />
      <path
        d="M12.0029 10.7399C11.4803 10.7411 10.975 10.5534 10.5813 10.2117L2.47368 3.15851C2.37465 3.07157 2.31426 2.94918 2.30573 2.8181C2.2972 2.68703 2.34121 2.55792 2.42815 2.45902C2.51508 2.36011 2.63787 2.29944 2.76967 2.29027C2.90147 2.28111 3.03155 2.3242 3.13149 2.41011L11.2396 9.46276C11.4513 9.6459 11.7225 9.74678 12.0032 9.74678C12.2838 9.74678 12.555 9.6459 12.7667 9.46276L20.8743 2.41011C20.9743 2.3242 21.1043 2.28111 21.2361 2.29027C21.3679 2.29944 21.4907 2.36011 21.5777 2.45902C21.6646 2.55792 21.7086 2.68703 21.7001 2.8181C21.6915 2.94918 21.6312 3.07157 21.5321 3.15851L13.4245 10.2112C13.0307 10.5529 12.5256 10.7408 12.0029 10.7399Z"
        fill="white"
      />
    </svg>
  );
};
